import React, { useState, useEffect } from "react";
import Values from "values.js";
import rgbToHex from "./utils";

const SingleColor = ({ rgb, weight, index, hexColor }) => {
  const [alert, setAlert] = useState(false);
  const bgc = rgb.join(",");
  console.log(rgb);
  const hex = rgbToHex(...rgb);
  const hexValue = `#${hexColor}`;
  const brightness = new Values(hexValue).getBrightness();
  useEffect(() => {
    const timeout = setTimeout(() => {
      setAlert(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [alert]);
  return (
    <article
      className={`color ${brightness < 50 ? "color-light" : ""}`}
      style={{ backgroundColor: `rgb(${bgc})` }}
      onClick={() => {
        setAlert(true);
        navigator.clipboard.writeText(hexValue);
      }}
    >
      <p className="percent-value">{weight}%</p>
      <p className={`color-value`}>
        {hexValue}
        <br />
        {brightness}
      </p>

      {alert && <p className="alert">copied to clipboard</p>}
    </article>
  );
};

export default SingleColor;
