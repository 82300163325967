import React, { Fragment, useEffect, useState } from "react";
import SingleColor from "./SingleColor";

import Values from "values.js";

function App() {
  const [color, setColor] = useState("#f15025");
  const [error, setError] = useState(false);
  const [sliderValue, setSliderValue] = useState(20);
  const [list, setList] = useState(new Values("#f15025").all(sliderValue));

  useEffect(() => {
    try {
      let colors = new Values(color).all(101 - parseInt(sliderValue, 10));
      setList(colors);
      setError(false);
    } catch (error) {
      console.log(error);
      setError(true);
    }
  }, [color, sliderValue]);

  return (
    <Fragment>
      <section className="container">
        <h3>Color Generator</h3>
        <form>
          <input
            type="text"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            placeholder="#f15025"
            className={`${error ? "error" : null}`}
          />
          <input
            type="range"
            min="1"
            max="100"
            value={sliderValue}
            onChange={(e) => setSliderValue(e.target.value)}
            className="slider"
            id="myRange"
          />
          <span className="slider-value">{sliderValue}</span>
        </form>
      </section>
      <section className="colors">
        {list.map((color, index) => {
          return (
            <SingleColor
              key={index}
              {...color}
              index={index}
              hexColor={color.hex}
            />
          );
        })}
      </section>
    </Fragment>
  );
}

export default App;
